import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import styles from './benefits.module.scss';
import HeroImage from '../../components/hero-image/hero-image';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import BenefitsOverviewSection from '../../components/allied-health/benefits/benefits-overview-section';
import BenefitsInsuranceSection from '../../components/allied-health/benefits/benefits-insurance-section';
import BenefitsHousingSection from '../../components/allied-health/benefits/benefits-housing-section';
import BenefitsRecruiterSection from '../../components/allied-health/benefits/benefits-recruiter-section';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const getAlliedBenefitsImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };
  const gastbyOGImg = new GatsbyImg('allied-benefits-og', data);
  const gatsbyHeroImg = new GatsbyImg('allied-benefits', data);

  return (
    <>
      <Helmet>
        <title>Allied traveler benefits - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="When you become an allied traveler with Trustaff, you have access to customizable benefits options including health insurance, dental, vision, 401k, and more."
        />
        <meta property="og:title" content="Allied traveler benefits" />
        <meta
          property="og:description"
          content="When you become an allied traveler with Trustaff, you have access to customizable benefits options including health insurance, dental, vision, 401k, and more."
        />
        <meta property="og:image" content={gastbyOGImg.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/allied-health/benefits/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage fluid={gatsbyHeroImg.fluidImg}>
          <div className={'columns'}>
            <div className="column is-7 is-offset-1">
              <div className={styles.heroTextArea}>
                <h1 className={`title is-2 has-text-white`}>
                  Allied traveler benefits
                </h1>
                <p className={`content`}>
                  What’s equally as important as taking care of your career?
                  Ensuring you have the benefits you need to take care of
                  yourself and your family. When you become an allied traveler
                  with Trustaff, you have access to customizable benefits
                  including medical plan options that start at just{' '}
                  <b>$24/week!</b>
                </p>
                <p className={`content`}>
                  Trustaff offers comprehensive traveler benefits to all our
                  allied healthcare professionals, including à la carte options
                  that allow you to enroll in only the plans that work best for
                  you.
                </p>
              </div>
            </div>
          </div>
        </HeroImage>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <BenefitsOverviewSection
            fluid={
              getAlliedBenefitsImageEdge('allied-benefits-sidebar-1').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <BenefitsInsuranceSection
            fluid={
              getAlliedBenefitsImageEdge('allied-benefits-sidebar-2').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div className={`section ${styles.sectionNoSidePaddingMobile}`}>
          <BenefitsHousingSection
            fluid={
              getAlliedBenefitsImageEdge('allied-benefits-sidebar-3').node
                .childImageSharp.fluid
            }
          />
        </div>
        <div
          className={`section ${styles.sectionNoSidePaddingMobile} ${styles.sectionBackgroundGrey}`}
        >
          <BenefitsRecruiterSection
            fluid={
              getAlliedBenefitsImageEdge('allied-benefits-sidebar-4').node
                .childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^allied-benefits/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
