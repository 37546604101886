import React from 'react';
import Img from 'gatsby-image';
import styles from './benefits-insurance-section.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns">
      <div
        className={`columns column is-12 is-10-desktop is-offset-1-desktop has-text-centered-mobile`}
      >
        <div className="column is-6">
          <h3 className="title is-5 has-text-info">
            Health insurance &amp; financial planning
          </h3>
          <div className="content">
            <p className={`content`}>
              Trustaff's travelers have a variety of insurance options to choose
              from, including medical coverage plans through{' '}
              <b>United Healthcare</b>. Options include medical, dental, and
              vision coverage for each employee, as well as any additional
              family members or dependents you may choose to add to your plan.
            </p>
            <p className="content">
              All Trustaff employees are eligible to make contributions to a
              401(k) savings account upon acceptance of their first assignment,
              with contributions matched by Trustaff after one year, as well as
              access to other financial planning services.
            </p>
          </div>
        </div>
        <div
          className={`column is-5 is-offset-1 ${styles.noSidePaddingMobile}`}
        >
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="travel nursing health insurance"
          />
        </div>
      </div>
    </div>
  );
};
